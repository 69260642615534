@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&family=Poppins:wght@400;500&family=Red+Hat+Display:wght@400;500&display=swap');
@import './variables.scss';
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Red Hat Display', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
